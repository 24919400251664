import { from, ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  InvalidationPolicyCache,
  RenewalPolicy,
} from '@nerdwallet/apollo-cache-policies';
import { onError } from '@apollo/client/link/error';
import { GetAccessToken } from './utility/LoginUtility';

import { GetStatusCode, GetErrMessage } from './utility/ApolloHelpers';

import getConfig from './lib/config';
import { trackingHeaders } from './apps/core/src/helpers/tracker';

/**
 * @param {import("./ApolloClient.types").GenerateClientOptions} options
 */
const GenerateClient = ({ onLoading }) => {
  let operationsCount = 0;
  let loadedOnce = false;

  /**
   * @type {typeof fetch}
   */
  const fetchIntercepted = (...args) => {
    if (loadedOnce) {
      return fetch(...args);
    }
    onLoading(true);

    operationsCount++;

    return fetch(...args)
      .then((results) => {
        operationsCount--;

        if (operationsCount === 0) {
          loadedOnce = true;

          onLoading(false);
        }

        return results;
      })
      .catch((error) => {
        operationsCount--;

        if (operationsCount === 0) {
          loadedOnce = true;

          onLoading(false);
        }

        console.error(error);
        throw error;
      });
  };

  const httpLink = createHttpLink({
    uri: `${getConfig('REACT_APP_REMOTE_URI')}graphql`,
    fetch: fetchIntercepted,
  });

  const authLink = setContext((request, { headers }) => {
    // may remove this check for `createTokenAccount` later
    const sharetoken = request.variables?.sharetokenHeader || null;

    const token =
      request.operationName === 'publicToken' ||
      request.operationName === 'createVirtiToken'
        ? null
        : GetAccessToken(
            request.operationName === 'refreshToken' ||
              request.operationName === 'checkLogin'
          );

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        // credentials: 'include',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
        ...(sharetoken
          ? {
              'X-Virti-Sharetoken': sharetoken,
            }
          : {}),
      },
    };
  });

  const trackerLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...trackingHeaders(),
        ...headers,
      },
    };
  });

  const cache = new InvalidationPolicyCache({
    dataIdFromObject: (object) => {
      // the field used to normalize the cache after mutations (leveraging auto-update)
      if (object.ID) {
        return `${object.__typename}:${object.ID}`;
      }
      return undefined;
    },
    invalidationPolicies: {
      // 1 minutes
      timeToLive: 1 * 60 * 1000,
      renewalPolicy: RenewalPolicy.WriteOnly,
      types: {
        AnalyticsEvent: {
          // 12 hours
          timeToLive: 12 * 60 * 60 * 1000,
        },
        AnalyticsSubmission: {
          // 12 hours
          timeToLive: 12 * 60 * 60 * 1000,
        },
        Submission: {
          // 12 hours
          timeToLive: 12 * 60 * 60 * 1000,
        },
        APINotification: {
          renewalPolicy: RenewalPolicy.AccessAndWrite,
        },
      },
    },
  });

  const logoutLink = onError((err) => {
    const message = GetErrMessage(err);
    const errCode = GetStatusCode(message);

    console.log(
      'error -',
      err,
      err?.graphQLErrors,
      err?.networkError,
      err?.operation,
      err?.operation?.operationName,
      err?.operation?.query,
      message,
      errCode
    );

    const doNotRedirect =
      !!err?.operation?.operationName &&
      ['checkLogin'].includes(err?.operation?.operationName);

    if (
      !doNotRedirect &&
      window.location.pathname !== '/logout' &&
      (errCode === 403 ||
        message === 'Invalid access' ||
        // chrome
        message.includes('Failed to fetch') ||
        // firefox
        message.includes('NetworkError') ||
        // safari
        message.includes('Load failed'))
    ) {
      console.error('logging out');
      window.location.href = '/logout?msg=' + encodeURI(message);
      return;
    }
  });

  const client = new ApolloClient({
    link: from([
      // stateLink,
      /*tokenRefreshLink,*/ logoutLink,
      authLink,
      trackerLink,
      httpLink,
    ]), // the order here is important
    cache,
    connectToDevTools: process.env.REACT_APP_ENV !== 'production',
  });
  return client;
};

export default GenerateClient;
